<div class="title-box">
    <div class="left-vector-box">
        <svg class="left-vector desktop" width="100" height="4">
            <path d="M 0 2 H 100" stroke="#00bee8" stroke-width="4" />
        </svg>
        <svg class="left-vector mobile" width="24" height="4">
            <path d="M 0 2 H 24" stroke="#00bee8" stroke-width="4" />
        </svg>
    </div>
    <h2 class="headline-2 ta-center">{{ print().headlines[2]}}</h2>
    <div class="right-vector-box">
        <svg class="right-vector" width="734" height="4">
            <path d="M 0 2 H 734" stroke="#00bee8" stroke-width="4" />
        </svg>
    </div>
</div>

<div class="content">
    <span class="hint-text">{{ print().projectIntro }}</span>

    <div class="project-group">
        <app-project *ngFor="let project of projects; index as i" [index]="i" [project]="project"></app-project>
    </div>
</div>

<img class="shadow" src="../../assets/img/skills_shadow.svg" alt="shadow">