<main class="main">
    <article class="content">
        <h2 class="title">{{ print().imprint }}</h2>

        <section class="imprint">
            <address class="address">
                Rudolf Johann Sachslehner<br>
                Wiener Straße 6-8/1/21<br>
                3430 Tulln an der Donau<br>
                {{ print().state }}
            </address>

            <p>
                Tel.: <a href="tel:+436641922794">+43 664 192 27 94</a><br>
                E-Mail: <a href="mailto:rudolf.sachslehner@gmx.at">rudolf.sachslehner&#64;gmx.at</a>
            </p>

            <p>{{ print().platform }}:
                <a href="https://ec.europa.eu/odr" target="_blank">https://ec.europa.eu/odr</a>
            </p>

            <p>{{ print().term }}</p>
        </section>
    </article>
</main>