<div [ngStyle]="setStyle('flex-direction', 'row-reverse', 'row')" class="project">
    <div data-aos="zoom-in" class="img-box">
        <img class="img" src="{{ project.path }}" [alt]="getAlt(project)">
    </div>

    <div [ngStyle]="setStyle('align-items', 'flex-end', 'flex-start')" class="content">
        <div [ngStyle]="setStyle('align-items', 'flex-end', 'flex-start')" class="text-box">
            <h3 class="title">{{ project.name }}</h3>
            <span class="skills">{{ project.skills }}</span>
            <p [ngStyle]="setStyle('text-align', 'right', 'left')" class="description">
                {{ project.description }}
            </p>
        </div>

        <div class="button-box">
            <button [disabled]="project.name == 'Join'" class="sec-btn disabled"
                (click)="linkData.redirect(project.link)">{{ print().testButton }}</button>
            <button class="tert-btn" (click)="linkData.redirect(project.github)">Github</button>
        </div>
    </div>
</div>