<div class="text-box mobile">
    <div class="title-vector-box">
        <h2 class="headline-2 w-395">{{ print().headlines[1] }}</h2>
        <div class="vector-box">
            <svg class="vector" width="32" height="4">
                <path d="M 0 2 H 32" stroke="#00bee8" stroke-width="4" />
            </svg>
        </div>
    </div>

    <p class="exp-text">{{ print().expText }}</p>
</div>

<div class="skill-group">
    <app-skill data-aos="flip-left" *ngFor="let skill of skills" [skill]="skill"></app-skill>
    <app-study-skill data-aos="fade-up-left"></app-study-skill>
</div>

<div class="text-box">
    <div class="title-vector-box desktop">
        <h2 class="headline-2 w-395">{{ print().headlines[1] }}</h2>
        <div class="vector-box">
            <svg class="vector" width="548" height="4">
                <path d="M 0 2 H 548" stroke="#00bee8" stroke-width="4" />
            </svg>
        </div>
    </div>

    <div class="another-skill-box">
        <p class="exp-text desktop">{{ print().expText }}</p>

        <div class="skill-button-box">
            <div class="another-skill-subbox">
                <h3 class="another-skill-title">
                    {{ print().anotherSkill[0] }} <br class="break"><span class="c-prim-2">{{ print().anotherSkill[1]
                        }}</span>?
                </h3>
                <p class="another-skill-text">{{ print().anotherText }}</p>
            </div>

            <button class="prim-btn" (click)="linkData.scroll('#contact')">{{ print().skillButton }}</button>
        </div>
    </div>
</div>

<img class="shadow" src="../../assets/img/skills_shadow.svg" alt="shadow">