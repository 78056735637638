<div class="hero-main">
    <div class="hero-box">
        <div class="hero-subbox">
            <img class="shadow" src="../../assets/img/hero_shadow.svg" alt="shadow">
        </div>
        <div class="hero-subbox">
            <img class="img" src="../../assets/img/hero.png" alt="image">
        </div>
        <div class="hero-subbox jc-start">
            <img class="bg" src="../../assets/img/hero_bg.svg" alt="background">
        </div>
    </div>

    <article class="text-button-box">
        <div data-aos="fade-left" class="text">
            <div class="vert-text">
                <span class="me-text">I am</span>
            </div>
            <div class="horiz-text">
                <span class="name">Rudolf Sachslehner</span>
                <span class="occupation">Frontend Developer</span>
            </div>
        </div>

        <button data-aos="fade-up-left" id="hero-btn" class="prim-btn" (click)="linkData.scroll('#contact')">{{
            print().heroButton }}</button>
    </article>
</div>

<div class="hero-bottom">
    <div class="contact-bar">
        <div class="vector-box">
            <svg class="vector" width="100" height="4">
                <path d="M 0 2 H 100" stroke="#00bee8" stroke-width="4" />
            </svg>
        </div>
        <div class="contact-links">
            <button class="social-btn git-hub-btn" (click)="linkData.redirect(linkData.gitHub)"></button>
            <button class="social-btn email-btn" (click)="linkData.scroll('#contact')"></button>
            <button class="social-btn linked-in-btn" (click)="linkData.redirect(linkData.linkedIn)"></button>
            <a class="hero-email" (click)="linkData.scroll('#contact')">rudolf.sachslehner&#64;gmx.at</a>
        </div>
    </div>

    <div class="scroll-hint-box">
        <span class="scroll-hint">{{ print().scrollHint }}</span>
        <img src="../assets/img/arrow_down.png" alt="arrow-down">
    </div>
</div>