<div class="title-box">
    <div class="vector-box">
        <svg class="vector" width="740" height="4">
            <path d="M 0 2 H 740" stroke="#00bee8" stroke-width="4" />
        </svg>
    </div>
    <h2 class="headline-2 ta-center">{{ print().headlines[3] }}</h2>
</div>

<div class="content">
    <div class="hint-box">
        <h3 class="hint-title">{{ print().contactIntro }}</h3>
        <div class="hint-text-group">
            <p>{{ print().contactText }}</p>
            <span>{{ print().contactLine[0] }} <b>{{ print().contactLine[1] }}</b></span>
        </div>
    </div>

    <div class="right-content">
        <app-form class="app-form"></app-form>
        <a class="top-arrow" href="#top"></a>
    </div>
</div>

<img class="shadow" src="../../assets/img/footer_shadow.svg" alt="shadow">