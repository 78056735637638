<div class="text-box w-475">
    <h2 class="headline-2 w-475">{{ print().headlines[0] }}</h2>
    <div class="content">
        <p class="intro-text">{{ print().introText }}</p>
        <div class="intro-list">
            <app-intro-item data-aos="zoom-out-right" *ngFor="let item of items" [item]="item"></app-intro-item>
        </div>
    </div>
</div>

<div class="img-box">
    <div class="img"></div>
    <div class="vector-box desktop">
        <svg class="vector" width="747" height="4">
            <path d="M 0 2 H 747" stroke="#4b47ff" stroke-width="4" />
        </svg>
    </div>
</div>

<img class="shadow" src="../../assets/img/about_me_shadow.svg" alt="shadow">