<footer class="footer">
    <div class="logo-imprint-group">
        <a data-aos="zoom-out" class="logo" href="#top">
            <span>R</span>
            <span class="c-prim-1">u</span>
            <span class="c-prim-2">d</span>
            <span>o</span>
            <span>l</span>
            <span>f</span>
        </a>
        <a class="imprint-link desktop" routerLink="imprint" (click)="linkData.top()">
            <span>{{ print().imprint }}</span>
            <img src="../../../../assets/img/imprint_underline.svg" alt="underline">
        </a>
    </div>

    <span class="copyright">&#169; Rudolf Sachslehner 2024</span>

    <div class="social-button-group">
        <button class="social-btn git-hub-btn l-0" (click)="linkData.redirect(linkData.gitHub)"></button>
        <button class="social-btn email-btn l-72" (click)="linkData.scroll('#contact')"></button>
        <button class="social-btn linked-in-btn l-144" (click)="linkData.redirect(linkData.linkedIn)"></button>
    </div>

    <a class="imprint-link mobile" routerLink="imprint" (click)="linkData.top()">
        <span>{{ print().imprint }}</span>
        <img src="../../../../assets/img/imprint_underline.svg" alt="underline">
    </a>
</footer>