<div class="study-skill">
    <svg class="skill-logo" width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_105153_4937)">
            <path
                d="M49.8288 25.245L30.6154 36.1892C30.2695 36.3926 29.8424 36.3926 29.4864 36.1892L10.2729 25.245C9.52027 24.8178 9.52027 23.7295 10.2729 23.2921L29.4864 12.3479C29.8322 12.1445 30.2594 12.1445 30.6154 12.3479L49.8288 23.2921C50.5815 23.7193 50.5815 24.8076 49.8288 25.245Z"
                fill="#00BEE8" />
            <path
                d="M46.5028 28.927V37.9489C46.5028 38.3557 46.2892 38.7219 45.9332 38.9253L30.6154 47.6522C30.2696 47.8556 29.8424 47.8556 29.4864 47.6522L14.1685 38.9253C13.8125 38.7219 13.5989 38.3557 13.5989 37.9489V28.927L22.0919 33.7583L29.4864 37.9794C29.8322 38.1828 30.2594 38.1828 30.6154 37.9794L38.0099 33.7583L46.5028 28.927Z"
                fill="#00BEE8" />
            <path
                d="M55.4535 14.0363L55.9824 13.6702C56.6232 13.2226 56.5418 12.2564 55.84 11.9207L51.6291 9.917C50.9171 9.58135 50.1136 10.1408 50.1746 10.9239L50.5815 15.5722C50.6527 16.3452 51.5376 16.7622 52.1783 16.3147L52.931 15.796C59.6237 26.5774 57.8234 40.9392 48.4659 49.6865C39.2609 58.2913 25.0721 59.3389 14.7279 52.1784C14.0363 51.7004 13.0802 51.8733 12.6022 52.5649C12.1241 53.2565 12.297 54.2126 12.9887 54.6907C18.1048 58.2303 24.0549 59.9696 29.9949 59.9696C37.4301 59.9696 44.8449 57.2437 50.551 51.9139C60.9968 42.1394 63.0107 26.0587 55.4535 14.0363Z"
                fill="#00BEE8" />
            <path
                d="M47.113 5.30948C35.5992 -2.66475 19.8033 -1.49506 9.55075 8.08622C-1.15954 18.1049 -3.0819 34.1144 4.9025 46.3402L4.16 46.8487C3.51922 47.2963 3.60059 48.2625 4.3024 48.5982L8.51328 50.6019C9.22527 50.9376 10.0288 50.3781 9.96777 49.595L9.56092 44.9467C9.48972 44.1737 8.60483 43.7567 7.96404 44.2042L7.41479 44.5907C0.315285 33.616 2.03422 19.2847 11.6358 10.3137C20.8408 1.70887 35.0296 0.651066 45.3738 7.82177C46.0654 8.29982 47.0215 8.12691 47.4996 7.43527C47.9776 6.74362 47.8047 5.78753 47.113 5.30948Z"
                fill="#00BEE8" />
        </g>
        <defs>
            <clipPath id="clip0_105153_4937">
                <rect width="60" height="59.939" fill="#00BEE8" transform="translate(0 0.0305176)" />
            </clipPath>
        </defs>
    </svg>
    <span class="skill-text">Continually learning</span>
    <img class="special-interest" src="../../../assets/img/special_interest.svg" alt="special-interest">
</div>